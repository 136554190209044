import "./src/styles/global.css"

import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"

export function onInitialClientRender() {
  console.log(process.env.NODE_ENV)
  if (process.env.NODE_ENV === "development") {
    if (firebase.functions && typeof firebase.functions === "function") {
      console.log("using firebase emulator")
      firebase.functions().useEmulator("local.taberon.com", 5001)
    }
  }
}
