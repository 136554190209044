import { createMuiTheme, responsiveFontSizes } from "@material-ui/core"

const fontFamily = "Source Sans Pro, sans-serif"
let theme = createMuiTheme({
  palette: {
    action: {},
    background: {
      default: "var(--c-white)",
    },
  },
  spacing: 8,
  typography: {
    allVariants: {
      fontFamily: fontFamily,
      fontWeight: 300,
      fontStyle: "normal",
      color: "var(--c-almost-black)",
    },
    h1: {
      fontWeight: 900,
      fontSize: 34,
    },
    h2: {
      fontWeight: "bold",
      fontSize: 22,
    },
    h3: {
      fontFamily: fontFamily,
      fontWeight: 600,
      fontSize: 18,
    },
    body1: {
      fontFamily: fontFamily,
      fontWeight: "normal",
      fontSize: 13,
    },
  },
  overrides: {
    MuiAppBar: {
      root: {
        backgroundColor: "var(--c-white)",
      },
    },
    MuiToolbar: {
      root: {
        backgroundColor: "var(--c-white)",
      },
    },
    MuiTab: {
      root: {
        fontFamily: fontFamily,
        fontWeight: 600,
        fontSize: 18,
      },
    },
    MuiButton: {
      containedPrimary: {
        background:
          "linear-gradient(90deg, #9240EC 0%, #8E3FED 26.62%, #843FF1 63.99%, #773CF3 98.29%)",
        backgroundColor: "transparent",
        fontStyle: "normal",
        fontWeight: 900,
        fontSize: 18,
        borderRadius: 30,
        width: "100%",
        height: "100%",
        textTransform: "none",
      },
    },
  },
})
theme = responsiveFontSizes(theme)

export default theme
